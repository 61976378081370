.notifications {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  contain: strict;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.notificationContainer {
  contain: content;
  overflow: hidden;
  opacity: 0;
  padding: 5px;
  animation: 0.3s ease showNotification forwards 0.5s;
}
.notificationContainer.disposed {
  animation: 0.3s ease hideNotification both;
}

.notification {
  pointer-events: auto;
}

@keyframes showNotification {
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes hideNotification {
  from {
    opacity: 1;
    visibility: visible;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
}

.messageBar {
  font-size: 12px;
  padding: 0 10px;
  margin: 3px 0;
}
