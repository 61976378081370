.root {
  position: relative;
  background-color: var(--figma-color-globalSideMenu);
  height: 100%;
  display: flex;
  flex-direction: column;
  color: var(--figma-color-sideMenuText);
  font-size: 14px;
}
.root:not(.isResizing) {
  transition: width 0.2s ease;
}
.rootResizeHandler {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: -4px;
  width: 7px;
  height: 100%;
  user-select: none;
  z-index: 1;
}
.root:not(.isCollapsed) .rootResizeHandler {
  cursor: col-resize;
}
.root.isAdmin {
  color: #000;
  background-color: #fff;
  border-right: 1px solid var(--figma-color-line-gray);
}

.root .versionListName {
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 17px 16px 0px 16px;
  padding: 10px;
  font-size: 14px;
  color: var(--figma-color-lightBlack);
  border-radius: 10px;
}
.root .versionListName .label {
  flex: 1;
  min-width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.root .versionListName:hover {
  background-color: var(--figma-color-sideMenuHover);
  cursor: pointer;
}
.firstMenu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 64px;
}
.firstMenu .content {
  display: flex;
  font-weight: 600;
  padding: 8px;
  margin-left: 20px;
  margin-right: 15px;
  border-radius: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.firstMenu .content.isAdminContent {
}

.firstMenu .content:not(.isAdminContent):hover {
  background-color: var(--figma-color-sideMenuHover);
  cursor: pointer;
}

.firstMenu .content .textWithTooltip {
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
}
.firstMenu .content .textWithTooltip .title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 180px;
  height: 22px;
}
.firstMenu .content .rightIcon {
  margin-left: 7px;
  display: flex;
  align-items: center;
}

.firstMenu .content .icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.firstMenu .content .logo {
  display: flex;
  justify-content: center;
}

.chevronDown {
  margin-left: 8px;
  padding-top: 3px;
}

.goToDefaulOrg {
  margin: 15px 0;
}

.innerRootMenu {
  margin: 16px 0;
}

.secondRoot {
  flex: 1;
  min-height: 0;
  overflow: auto;
}

.secondMenu {
  margin: 16px 0;
}
.thirdMenu {
  margin: 16px 0;
}
.root .versionListName {
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 17px 16px 0px 16px;
  padding: 10px;
  font-size: 14px;
  color: var(--figma-color-lightBlack);
  border-radius: 10px;
}
.root .versionListName .label {
  flex: 1;
  min-width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--figma-color-sideMenuText);
}

.versionAddButton {
  color: var(--figma-color-sideMenuText);
  flex-shrink: 0;
}

.secondRoot .versionList {
  padding: 0px 20px;
}

.thirdRoot {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 8px;
}
.thirdRoot:hover {
  background-color: var(--figma-color-sideMenuHover);
  cursor: pointer;
}

.thirdRoot .chevron {
  display: flex;
  height: 50px;
  align-items: center;
  padding-right: 15px;
}

.hoverItem:hover {
  background-color: var(--figma-color-sideMenuHover);
  cursor: pointer;
}

@media print {
  .root {
    display: none;
  }
}
