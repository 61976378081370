.divider {
  margin: 0 0;
  border-bottom: 1px solid var(--figma-color-line-gray);
}
.menuNode {
  margin: 0px 16px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  height: 40px;
  color: var(--figma-color-sideMenuText);
}

.menuNode.children {
  margin: 0 16px 0 26px;
}
.menuNode .text {
  flex: 1;
  min-width: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.menuNode .text.isAdminMode {
  color: #000;
}
.nestedElement {
  margin: 0px 16px 0px 26px;
  padding: 8px 16px;
  border-radius: 10px;
  color: var(--figma-color-globalSideMenu-innerText);
  font-size: 14px;
}

.optimizeSummaryContainer {
  cursor: auto;
}
.optimizeSummaryContainer .top {
  display: flex;
  margin-bottom: 10px;
  cursor: pointer;
}
.optimizeSummaryContainer .top .versionId {
  flex: 1;
  min-width: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.optimizeSummaryContainer .top .weight {
}

.optimizeSummaryContainer .noSummary {
  flex: 1;
  min-width: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.summaryItemNode {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.parentItem {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.parentItem .nameAndIcon {
  flex: 1;
  display: flex;
  align-items: end;
  gap: 3px;
  min-width: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.parentItem .nameAndIcon .name {
}
.parentItem .nameAndIcon .icon {
  width: 18px;
  height: 18px;
  color: inherit;
}
.parentItem .score {
  display: flex;
  align-items: end;
  gap: 4px;
  width: max-content;
  min-width: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.parentItem .score .valueAndUnit {
  display: flex;
  align-items: end;
  gap: 2px;
  margin-left: 3px;
  min-width: 0;
}

.parentItem .score .valueAndUnit .value {
  font-size: 14px;
  flex: 1;
  min-width: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.parentItem .score .valueAndUnit .unit {
  font-size: 12px;
  width: 40px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.parentItem .score .diff {
  font-size: 11px;
}

.childItems {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-left: 16px;
  margin: 8px 0;
}
.rootItems {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.referencedSheetDisplayName {
  color: var(--figma-color-innerText-stress);
}
