.inputRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.versionTitleInput {
  width: 300px;
  margin: 10px 0;
}
