.readContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 14px;
  font-weight: 300;
  margin: 25px 0;
  color: #626060;
}
.readContainer .text {
  color: #000;
  font-weight: 400;
}

.divider {
  border-top: 1px solid var(--figma-color-line-blueGray);
  margin: 15px 0;
}

.flexContainer {
  display: flex;
  align-items: center;
  gap: 20px;
}
