.loadingApp {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 150px;
  transform: translateX(-50%) translateY(-50%);
  text-align: center;
}

.loaded .loadingApp {
  display: none;
}

.loadingApp .bar {
  margin-top: 5px;
  position: relative;
  height: 6px;
  border-radius: 2px;
  background-color: #acece6;
  overflow: hidden;
}

.loadingApp .bar:before {
  content: "";
  display: block;
  width: 33.3%;
  height: 100%;
  background-color: #26a69a;
  animation: loadingApp 1s linear infinite;
}

@keyframes loadingApp {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(310%);
  }
}
