.dialogSubText {
  font-weight: normal;
  font-size: 14px;
  margin: 10px 0;
}

.errorInfoTitle {
  margin: 15px 0 3px;
  font-size: 14px;
}
.errorResponse {
  padding: 5px;
  background-color: #ffd1d1;
  word-break: break-all;
}

.progressing {
  padding-top: 20px;
}

.spinner {
  overflow: hidden;
  padding-top: 20px;
}

.titleWithIcon {
  display: flex;
  align-items: center;
}

.errorIcon {
  color: var(--figma-color-execution-red);
  margin-right: 5px;
}

.successIcon {
  color: var(--figma-color-success-green);
  margin-right: 5px;
}
