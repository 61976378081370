.container {
  display: flex;
  min-height: 100vh;
  background-color: #fbfafc;
  justify-content: center;
  align-items: center;
}
.container .contentBox {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
}
.container .contentBox .logo {
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
}
