.wrapper {
  height: max-content;
  min-width: max-content;
}

.header {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
  border: 1px solid var(--figma-color-line-gray);
  border-bottom: none;
  padding: 22px 24px;
  padding-bottom: 18px;
  border-radius: 20px 20px 0 0;
}

.leftEndContent {
  padding-left: 5px;
}

.rightEndContent {
  display: flex;
  align-items: center;
}

.body {
  background-color: #fff;
  padding: 22px 24px;
  width: 100%;
  border-radius: 0 0 20px 20px;
  border: 1px solid var(--figma-color-line-gray);
  overflow-x: auto;
  white-space: nowrap;
}

.body .message {
  font-size: 14px;
}

.dragAndDrop {
}

.dragAndDrop .file {
  background-color: var(--figma-color-snowWhite);
  border: 1px solid #ebebeb;
  height: 106px;
  width: 436px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 14px;
  margin: 20px 0;
}
.dragAndDrop .file:hover {
  background-color: var(--figma-color-snowWhite-stress);
  cursor: pointer;
}

.dragAndDrop .file.drag {
  background-color: var(--figma-color-snowWhite-stress);
  cursor: pointer;
}
.dragAndDrop .file.disable {
  background-color: var(--figma-color-snowWhite-stress);
  cursor: no-drop;
  color: var(--figma-color-gray60);
}

.dragAndDrop .uploadedFileList {
  font-size: 14px;
  margin-bottom: 10px;
  color: var(--figma-color-globalSideMenu-innerText);
}

.dragAndDrop .uploadedData {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: max-content;
}

.dragAndDrop .uploadedData .leftContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 436px;
}
.dragAndDrop .uploadedData .leftContent .info {
  display: flex;
  align-items: center;
}

.dragAndDrop .uploadedData .leftContent .info .name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 220px;
}
