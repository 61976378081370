.loadingCover {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px;
}

.loadingText {
  font-size: 14px;
  color: var(--figma-color-lightBlack);
}

.loadingCoverWithoutText {
  margin: auto;
  padding: 16px;
}
