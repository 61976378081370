.container {
  display: flex;
  min-height: 100vh;
  background-color: #fbfafc;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.container .contentBox {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  position: relative;
}
.container .contentBox .logo {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.container .contentBox .message {
  margin-bottom: 20px;
  font-size: 16px;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.button {
  width: 168px;
  height: 36px;
  border-radius: 8px;
}

.messageWrapper {
  margin-bottom: 20px;
}

.message {
  display: flex;
  gap: 20px;
  max-width: 500px;
}
