.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 16px 0;
  margin-left: 48px;
}

.input {
  width: 432px;
}

.dropdown {
  width: 432px;
}

.algorithmVersionId {
  font-size: 14px;
  color: var(--figma-color-lightBlack);
}

.errorMessage {
  color: var(--figma-color-execution-red);
}
