.container {
  max-height: 200px;
  overflow-y: scroll;
  margin: 20px 0;
}
.organizationLink {
  display: flex;
  padding: 8px;
  background-color: #fff;
  border: 1px solid var(--figma-color-line-gray);
}
.organizationLink:hover {
  background-color: var(--figma-color-highLight);
  cursor: pointer;
}
.organizationDisableLink {
  background-color: #fff;
  color: var(--figma-color-innerText-stress);
}
.organizationDisableLink:hover {
  background-color: #fff;
  cursor: not-allowed;
}
