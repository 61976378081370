.container {
  padding: 100px 150px;
  background-color: #fff;
  width: 992px;
  min-height: 426px;
  border-radius: 8px;
}

.errorIcon {
  color: var(--figma-color-execution-red);
}

.buttonContainer {
  display: flex;
  gap: 23px;
}

.errorInfoContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.errorInfoHeading {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #5c5c5c;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}

.errorInfoDescription {
  color: #505050;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.missingPermissionsList {
  padding: 5px 0;
  list-style-position: inside;
}

.details {
  padding: 10px 0;
}

.errorSummary {
  border: 1px solid #e0e0e0;
  padding: 5px;
  margin: 10px 0;
}

.errorDetails {
  font-size: 12px;
  border: 1px solid #e0e0e0;
  max-width: 900px;
  overflow-x: scroll;
  padding: 5px;
  margin: 10px 0;
}

.errorDisplayWrapper {
  padding: 10px;
}

.buttons {
  display: flex;
  padding: 10px;
  gap: 20px;
}
