@import url(https://fonts.googleapis.com/earlyaccess/notosansjp.css);

html {
  font-size: 62.5%;
}

body {
  --figma-color-sideMenuText: #ebebeb;
  --figma-color-sideMenuText-stress: #999999;
  --figma-color-innerText: #201f1e;
  --figma-color-innerText-stress: #999999;
  --figma-color-innerText-disabled: #66333333;
  --figma-color-keycolor: #7d77c0;
  --figma-color-darkblue: #576175;
  --figma-color-globalSideMenu: #060521;
  --figma-color-globalSideMenu-innerText: #afafaf;
  --figma-color-localSideMenu: #0e1126;
  --figma-color-sideMenuHover: rgba(175, 175, 175, 0.15);
  --figma-color-sideMenuSelected: rgba(175, 175, 175, 0.25);
  --figma-color-line-gray: #afafaf4e;
  --figma-color-line-blueGray: #eaedf3;
  --figma-color-bluewhite: #f8f9fa;
  --figma-color-table-border: #dde7f0;
  --figma-color-table-background-hover: #f6f8fe;
  --figma-color-gray60: #bcc0c9;
  --figma-color-gray100: #f6f8fa;
  --figma-color-gray500: #cccccc;
  --figma-color-offWhite: #fafafa;
  --figma-color-success-green: #00b15a;
  --figma-color-execution-red: #ff3300;
  --figma-color-lightGray: #ebebeb;
  --figma-color-lightBlack: #5c5c5c;
  --figma-color-highLight: #e9e9e9;
  --figma-color-snowWhite: #f7f7fa;
  --figma-color-snowWhite-stress: #f0f0f0;
  --figma-color-theme-primary: #1f56e3;
  --figma-color-status-error: #ed3131;
  --figma-color-status-warning: #ebad00;
  --figma-color-fluentUI-hover-gray: #f3f2f1;
  --figma-color-fluentUI-active-gray: #edebe9;
  --figma-color-fluentUI-disabled-gray: #f3f2f1;
  color: var(--figma-color-innerText);
  background-color: var(--figma-color-snowWhite);
  font-family: "Noto Sans JP", sans-serif;
  font-size: 1.6rem;
  line-height: 1.4;
}

@page {
  margin: 15mm;
}
:global(.printPageBox) {
  width: 210mm;
  height: 297mm;
  padding: 15mm;
  margin: 15px;
  box-shadow: 0 0 10px #ccc;
}
@media print {
  :global(.printPageBox) {
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    box-shadow: none;
  }
}
